import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DecimalPipe } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MsalGuardConfiguration, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxSliderModule } from "ngx-slider-v2";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { msalConfig, protectedResources } from "./auth-config";
import { ECSCommonModule } from "./ecs-common/ecs-common.module";
import { EndCustomerPortalFooterComponent } from "./end-customer-portal-footer/end-customer-portal-footer.component";
import { EndCustomerPortalHeaderComponent } from './end-customer-portal-header/end-customer-portal-header.component';
import { EndCustomerPortalSidebarComponent } from "./end-customer-portal-sidenav/end-customer-portal-sidenav.component";
import { AuthGuard } from './guards/auth.guard';
import { CustomHttpInterceptor } from "./services/http-interceptor.service";


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}
export const httpInterceptorProviders = [
  // { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
];

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: { scopes: protectedResources.api.scopes }
  };
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig)
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(protectedResources.api.endpoint, protectedResources.api.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [
    AppComponent,
    EndCustomerPortalHeaderComponent,
    // SignInComponent,
    // SignUpSignInComponent,
    // BasicDetailCaptureComponent,
    // OtherDetailsComponent,
    EndCustomerPortalFooterComponent,
    EndCustomerPortalSidebarComponent,
    // HelpComponent,
    // ContactComponent, DocumentsComponent, MediaComponent, DashboardComponent, SorryScreenComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // // NgbOffcanvasModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
    }),
    NgxSpinnerModule,
    // ConfiguratorModule,
    MsalModule,
    NgxSliderModule,
    ECSCommonModule,
    // ConfiguratorModule,
    ToastrModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
    AuthGuard,
    //MsalService,
    // MsalGuard,
    // MsalBroadcastService,
    // {
    //   provide: MSAL_INSTANCE,
    //   useFactory: MSALInstanceFactory
    // },
    // {
    //   provide: MSAL_GUARD_CONFIG,
    //   useFactory: MSALGuardConfigFactory
    // },
    // {
    //   provide: MSAL_INTERCEPTOR_CONFIG,
    //   useFactory: MSALInterceptorConfigFactory
    // },
    DecimalPipe,
    // GraphRequest
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

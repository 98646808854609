<div class="container container-desktop position-relative">

  <end-customer-portal-configurator-header [pageHeading]="'configurator.step2.heading'" [currentStep]="2"
    [routeToNextStep]="'/configurator/step3'"
    [isActive]="configuratorService.configurator.step2UserInteracted"></end-customer-portal-configurator-header>
  <div class="steps w-100">
    <div class="content-wrapper row  justify-content-between">
      <div class="left-content col-7 text-center">
        <div class="d-flex justify-content-center">
          <div class="top-section">
            <h1 class="title">{{'configurator.step2.subheading1' | translate}}</h1>
            <p class="paragraph">{{'configurator.step2.paragraph' | translate}}</p>
          </div>
        </div>
        <div class="slider-section">
          <p>{{'configurator.step2.subheading2' | translate}}</p>
          <div class="custom-slider">
            <ngx-slider [(value)]="value" [options]="options" (valueChange)="
            this.configuratorService.configurator.step2UserInteracted = true;bindValue()"></ngx-slider>
          </div>
          <h1>{{value*1000| number:'':'de-DE'}} kWh/Jahr</h1>
        </div>
      </div>
      <div class="col-5 right-content position-relative">
        <div class="containers h-100">
          <video  class="video-content h-100" *ngIf="isDesktop" id="video2"  playsInline>
            <source [src]="videoSrcFw" type="video/mp4">
          </video>
          <!-- <video id="video2a" [hidden]="videoSrc == videoSrcFw" style="width: 100%; height: 100%">
            <source [src]="videoSrcBw" type="video/mp4">
          </video> -->
          <video *ngIf="isMobile" id="video2" class="video-content" playsInline>
            <source [src]="videoSrcFwMobile" type="video/mp4">
          </video>
          <video *ngIf="isTablet" id="video2" playsInline>
            <source [src]="videoSrcFwTablet" type="video/mp4">
          </video>
        </div>
<!--        <end-customer-action-btn [currentStep]="2" [routeToNextStep]="'/configurator/step3'"></end-customer-action-btn>-->

      </div>
    </div>

  </div>
  <end-customer-portal-configurator-footer [routeToNextStep]="'configurator/step3'" [currentStep]="2"
    [isActive]="configuratorService.configurator.step2UserInteracted"></end-customer-portal-configurator-footer>
</div>

<div class="container container-desktop position-relative">
  <!--  <end-customer-portal-configurator-header [pageHeading]="'configurator.step5.heading'"-->
  <!--    [currentStep]="5"></end-customer-portal-configurator-header>-->
  <end-customer-portal-configurator-header [pageHeading]="'configurator.step5.heading'" [currentStep]="5"
    [routeToNextStep]="'/configurator/step5'"
    [isActive]="configuratorService.configurator.step5UserInteracted"></end-customer-portal-configurator-header>
  <div class="steps w-100">
    <div class="content-wrapper row justify-content-between">
      <div class="left-content col-7 text-center">
        <div class="d-flex justify-content-center">
          <div class="top-section">
            <h1 class="title"> {{'configurator.step5.subheading' | translate}}</h1>
            <p class="paragraph">{{'configurator.step5.paragraph' | translate}}</p>
          </div>
        </div>
        <div class="slider-section ">
          <p>{{'configurator.step5.slidertitle' | translate}}</p>
          <div class="custom-slider w-100">
            <ngx-slider [(value)]="configuratorService.configurator.knowledgeLevel" [options]="options"
              (valueChange)="configuratorService.configurator.step5UserInteracted = true;"></ngx-slider>
          </div>
          <h1>
            <ng-container [ngSwitch]="configuratorService.configurator.knowledgeLevel">
              <ng-container *ngSwitchCase="1">{{'configurator.step5.knowledgelevel.case1' | translate}}</ng-container>
              <ng-container *ngSwitchCase="2">{{'configurator.step5.knowledgelevel.case2' | translate}}</ng-container>
              <ng-container *ngSwitchCase="3">{{'configurator.step5.knowledgelevel.case3' | translate}}</ng-container>
              <ng-container *ngSwitchCase="4">{{'configurator.step5.knowledgelevel.case4' | translate}}</ng-container>
              <ng-container *ngSwitchCase="5">{{'configurator.step5.knowledgelevel.case5' | translate}}</ng-container>
            </ng-container>
          </h1>
        </div>

        <div class="radioOption d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-start justify-content-center gap-2">
            <div class="form-check mt-4 text-start">
              <input class="custom-checkbox me-2" type="checkbox" (change)="configuratorService.configurator.step5UserInteracted = true;configuratorService.configurator.knowledgeOption = KnowledgeLevelOption.alreadyOperate; knowledgeOption2 = false;
                knowledgeOption3 = false;" [(ngModel)]="knowledgeOption1" id="alreadyOperate">
              <label class="form-check-label fs18" for="alreadyOperate">
                {{'configurator.step5.checkbox1' | translate}}
              </label>
            </div>

            <div class="form-check mt-8 text-start">
              <input class="custom-checkbox me-2" type="checkbox" (change)="configuratorService.configurator.step5UserInteracted = true;configuratorService.configurator.knowledgeOption = KnowledgeLevelOption.planning; knowledgeOption1 = false;
                knowledgeOption3 = false;" [(ngModel)]="knowledgeOption2" id="planning">
              <label class="form-check-label fs18" for="planning">
                {{'configurator.step5.checkbox2' | translate}}
              </label>
            </div>
            <div class="form-check mt-8 text-start">
              <input class="custom-checkbox me-2" type="checkbox" (change)="configuratorService.configurator.step5UserInteracted = true;configuratorService.configurator.knowledgeOption = KnowledgeLevelOption.notLookingInto; knowledgeOption2 = false;
                knowledgeOption1 = false;" [(ngModel)]="knowledgeOption3" id="notLookingInto">
              <label class="form-check-label fs18" for="notLookingInto">
                {{'configurator.step5.checkbox3' | translate}}
              </label>
            </div>


          </div>
        </div>


      </div>
      <div class="col-5 right-content p-0 position-relative">
        <div class="containers d-flex h-100">
          <video *ngIf="isDesktop" id="video5" class="w-100 h-100 object-fit-cover overflow-hidden" playsInline>
            <source [src]="videoSrcFw" type="video/mp4">
          </video>
          <video *ngIf="isMobile" id="video5" class="video-content" playsInline>
            <source [src]="videoSrcFwMobile" type="video/mp4">
          </video>
          <video *ngIf="isTablet" id="video5" playsInline>
            <source [src]="videoSrcFwTablet" type="video/mp4">
          </video>
          <!-- <video class="w-100 h-100" id="video5a" [hidden]="videoSrc == videoSrcFw"
            style="overflow: hidden; object-fit: cover">
            <source [src]="videoSrcBw" type="video/mp4">
          </video> -->
        </div>
        <!--        <end-customer-action-btn [currentStep]="5" [routeToNextStep]="'/configurator/step6'"></end-customer-action-btn>-->

      </div>


    </div>

  </div>
  <end-customer-portal-configurator-footer [routeToNextStep]="'configurator/step6'" [currentStep]="5"
    [isActive]="configuratorService.configurator.step5UserInteracted"></end-customer-portal-configurator-footer>
</div>
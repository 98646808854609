<div class="d-flex justify-content-center align-items-center flex-column">
  <div class="d-flex flex-column justify-content-center align-items-center pb-4">
    <h3 class="chatHeading text-center text-black">
      <span translate>{{mainHeading}}</span> {{value1.toFixed(2)}} %
    </h3>
    <h3 class="chatHeading chatHeading2 text-center" [ngStyle]="{'color':color1 }">
      <span translate>{{subHeading}}</span>:
      {{batterySwitch ? increasedRatio.toFixed(2) +'%' : '--'}}
    </h3>
  </div>
  <div *ngIf="isTablet" style="display: block; width: 500px; height: 500px;">
    <canvas baseChart [data]="pieChartData" [options]="options" [type]="'doughnut'"></canvas>
  </div>
  <canvas *ngIf="!isTablet" baseChart [data]="pieChartData" [options]="options" [type]="'doughnut'"></canvas>
  <p *ngIf="!toBePrinted" class="text-center legend-text" style="padding-top: 10%;">{{'piechart.switch.label' |
    translate}}</p>
  <div *ngIf="!toBePrinted" class="d-flex justify-content-center align-items-center">
    <span class="text-black me-2" translate>Off</span>
    <div class="form-check form-switch form-switch-lg me-2">
      <input class="custom-switch form-check-input rounded" (change)="onSwitchChange()" [(ngModel)]="batterySwitch"
        type="checkbox" role="switch" id="flexSwitchCheckDefault">
    </div>
    <span class="text-black ms-2" translate>On</span>
  </div>
</div>
<div class="container container-desktop position-relative">
  <!--  <end-customer-portal-configurator-header [pageHeading]="'configurator.step6.heading'"-->
  <!--    [currentStep]="6"></end-customer-portal-configurator-header>-->

  <end-customer-portal-configurator-header [pageHeading]="'configurator.step6.heading'" [currentStep]="6"
    [routeToNextStep]="'/configurator/step6'"
    [isActive]="configuratorService.configurator.step6UserInteracted"></end-customer-portal-configurator-header>
  <div class="steps w-100">
    <div class="content-wrapper row  justify-content-between">
      <div class="left-content col-7 text-center">
        <div class="d-flex justify-content-center">
          <div class="top-section">
            <h1 class="title">{{'configurator.step6.subheading' | translate}}</h1>
            <p class="paragraph" [innerHTML]="'configurator.step6.paragraph' | translate"></p>
            <!--            <p class="paragraph">{{'configurator.step6.paragraph1' | translate}}</p>-->
          </div>
        </div>
        <div class="optimization d-flex flex-column gap-3 w-100">
          <div class=" container w-100" [ngClass]="{'showBor':showSelfConsumptionText, 'headerPadding': true}">
            <div class="optimizationAccordion">
              <div>
                <div>
                  <div class="d-flex">
                    <input class="custom-checkbox checkbox-item me-2 mb-1" type="checkbox"
                      (change)="updateApplicationList(Application.selfConsumption)" [(ngModel)]="selfConsumptionModel"
                      [value]="Application.selfConsumption">
                    <label class="green-color-font header-label pointer-cursor"
                      (click)="toggleShowSelfConsumptionText()">
                      {{'configurator.step6.checkbox1' | translate}}
                    </label>
                    <img class="me-1 pointer-cursor"
                      [src]="showSelfConsumptionText ? '/assets/chevron up.svg' : '/assets/chevron down.svg'" alt=""
                      (click)="toggleShowSelfConsumptionText()">
                  </div>
                </div>
                <div>
                  <div *ngIf="showSelfConsumptionText" class="ms-3 text-start px-1 py-3">
                    {{'configurator.step6.checkbox1value' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container w-100" [ngClass]="{'showBor':showPeakShavingText,'headerPadding':true}">
            <div class="optimizationAccordion">
              <div>
                <div>
                  <div class="d-flex">
                    <input class="custom-checkbox checkbox-item me-2 mb-1" type="checkbox"
                      [value]="Application.peakShaving" (change)="updateApplicationList(Application.peakShaving)"
                      [(ngModel)]="peakShavingModel">
                    <label class="green-color-font header-label pointer-cursor" (click)="toggleShowPeakShavingText()">
                      {{'configurator.step6.checkbox2' | translate}}
                    </label>
                    <img class="me-1 pointer-cursor"
                      [src]="showPeakShavingText ? '/assets/chevron up.svg' : '/assets/chevron down.svg'" alt=""
                      (click)="toggleShowPeakShavingText()">
                  </div>
                </div>
                <div>
                  <div *ngIf="showPeakShavingText" class="ms-3 text-start px-1 py-3">
                    {{'configurator.step6.checkbox2value' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container w-100" [ngClass]="{'showBor':showBackupPowerText, 'headerPadding':true}">
            <div class="optimizationAccordion">
              <div>
                <div>
                  <div class="d-flex">
                    <input type="checkbox" class="custom-checkbox checkbox-item me-2 mb-1"
                      [value]="Application.backupPower" (change)="updateApplicationList(Application.backupPower)"
                      [(ngModel)]="backupPowerModel" id="chkAccordion3All">
                    <label class="green-color-font header-label pointer-cursor" (click)="toggleShowBackupPowerText()">
                      {{'configurator.step6.checkbox3' | translate}}
                    </label>
                    <img class="me-1 pointer-cursor"
                      [src]="showBackupPowerText ? '/assets/chevron up.svg' : '/assets/chevron down.svg'" alt=""
                      (click)="toggleShowBackupPowerText()">
                  </div>
                </div>
                <div *ngIf="showBackupPowerText">
                  <div class="ms-3 text-start px-1 py-3">
                    {{'configurator.step6.checkbox3value' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container  w-100">

            <div class="someThingELse p-2" id="someThingELse">
              <!-- <div class="custom-checkbox d-flex gap-1 mb-4">
                <input type="checkbox" id="leftCheckbox" [(ngModel)]="somethingElseModel">
                <span class="checkmark"></span>
                <label class="someThingELseLabel text-start" for="leftCheckbox">{{'configurator.step1.somethingElse' |
                  translate}}</label>
              </div> -->
              <div class="items w-100">
                <!-- <div class="" style="float: left !important;">
                  <input class="custom-checkbox me-2 mb-4" type="checkbox" value="Application.somethingElse"
                    [(ngModel)]="somethingElseModel" id="chkAccordion4All"
                    (change)="updateApplicationList(Application.somethingElse)">
                  {{'configurator.step6.checkbox4' | translate}}
                </div> -->
                <div>
                  <textarea class="form-control" rows="3" [maxlength]="200"
                    [(ngModel)]="configuratorService.configurator.applicationSomethingElse"
                    placeholder="{{'configurator.step6.placeholder' | translate}}"></textarea> <br>
                  <label class="nopadding clrlbl" *ngIf="configuratorService.configurator.applicationSomethingElse">
                    {{configuratorService.configurator.applicationSomethingElse.length}}/200</label>
                  <br />
                  <p class="bottom-paragraph">
                    {{'configurator.step6.paragraph1' | translate}}
                    <span class="highlighted-text">{{'configurator.step6.paragraph2' | translate}}</span>
                    {{'configurator.step6.paragraph3' | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-5 right-content p-0">
        <div class="containers d-flex h-100">
          <video *ngIf="isDesktop" id="video6" class="w-100 h-100 object-fit-cover overflow-hidden" playsInline>
            <source src="assets/videos-rel1/lead_gen_animations_solo_new_x01/video/leadgen_applications_desktop_v3.mp4"
              type=" video/mp4">
          </video>
          <video *ngIf="isMobile" id="video6" class="video-content" playsInline>
            <source [src]="videoSrcFwMobile" type="video/mp4">
          </video>
          <video *ngIf="isTablet" id="video6" playsInline>
            <source [src]="videoSrcFwTablet" type="video/mp4">
          </video>
        </div>
        <div class="top-img-section position-absolute">
          <div *ngIf="configuratorService.configurator.application.includes(1)" class="img-btn">
            <img src="assets/img/configurator/step-6-img-1.png" alt="">
          </div>
          <div *ngIf="configuratorService.configurator.application.includes(0)" class="img-btn">
            <img src="assets/img/configurator/step-6-img-2.png" alt="">
          </div>
          <div *ngIf="configuratorService.configurator.application.includes(2)" class="img-btn">
            <img src="assets/img/configurator/step-6-img-3.png" alt="">
          </div>
        </div>
        <div class="position-relative">
          <!--          <end-customer-action-btn [currentStep]="6"-->
          <!--            [routeToNextStep]="'/configurator/step6'"></end-customer-action-btn>-->
        </div>
      </div>
    </div>
  </div>
  <end-customer-portal-configurator-footer [currentStep]="6"
    [isActive]="configuratorService.configurator.step6UserInteracted"></end-customer-portal-configurator-footer>
</div>
import { Component, Input, OnInit } from "@angular/core";
import { simulationResponse } from "src/app/core/models/simulationResponse.model";
import { ConfiguratorService } from "../utility/configurator.service";
import { VerificationService } from "../../auth/verification/verification.service";

@Component({
  selector: 'end-customer-response-table',
  templateUrl: './response-table.component.html',
  host: {
    '[class.successpage]': '!toBePrinted'
  },
  styleUrls: ['./response-table.component.scss']
})
export class ResponseTableComponent implements OnInit {
  constructor(public configuratorService: ConfiguratorService,
    public vs: VerificationService,) { }
  @Input() toBePrinted: boolean = false;
  @Input() imageName!: string;
  @Input() productName!: string;
  featureList: Map<string, string[]> = new Map();

  // backupShare = 0;
  ngOnInit() {
    this.featureList.set('TS 48 V', ['Modular in 4, 8 kWh Schritten ausbaubar',
      ' 3 verschiedene Rackgrößen',
      ' Optimiert für den Betrieb mit SMA Sunny Island',
      'Flexible Konfiguration und Erweiterung auch Jahre später möglich',
      ' Gebaut für eine Lebensdauer von 30 Jahren bzw. 8.000 Vollzyklen',
      'Ladegeschwindigkeit 1 C',
      ' Sicherste Zelltechnologie']);
    this.featureList.set('TS-I HV 70 / 80 E', [
      'Vielseitige Anwendungsmöglichkeiten',
      ' Inkl. integriertem Wechselrichter, TESVOLT Energy Manager & myTESWORLD Portal',
      ' Inkl. Power Quality Technology',
      ' 100 % Entladetiefe und 35 % höhere Energiedichte',
      ' Reduzierter Platzbedarf',
      ' Ladegeschwindigkeit 1 C',
      ' Sicherste Zelltechnologie'
    ]);
    this.featureList.set('TS-I HV 100 E', [
      'Vielseitige Anwendungsmöglichkeiten',
      'Inkl. integriertem Wechselrichter, TESVOLT Energy Manager & myTESWORLD Portal',
      'Inkl. Power Quality Technology',
      '100 % Entladetiefe und 35 % höhere Energiedichte',
      'Reduzierter Platzbedarf',
      'Ladegeschwindigkeit 1 C',
      'Sicherste Zelltechnologie'
    ]);
    this.featureList.set('TS HV 30-80 E', [
      'Optimiert für den dauerhaften Einsatz in Industrie, Gewerbe und Landwirtschaft',
      'Mehr Variantenvielfalt: von 32 - 80 kWh',
      'Prismatische Lithium-NMC-Batteriezellen (Samsung SDI)',
      'Bis zu 98 % Wirkungsgrad der Batterie',
      'Freie Wahl beim Energiemanagementsystem (je nach Anforderung)',
      ' 10 Jahre Systemgarantie, 10 Jahre Kapazitätsgarantie',
      ' Vorbereitung für die Verbundkraftwerk-Nutzung',
      '100 % Entladetiefe und 35 % höhere Energiedichte']);
    this.featureList.set('TS-I HV 80', [
      ' Vielseitige Anwendungsmöglichkeiten',
      'Inkl. integriertem Wechselrichter, TESVOLT Energy Manager & myTESWORLD Portal',
      'Inkl. Power Quality Technology',
      ' Flexible Konfiguration und Erweiterung auch Jahre später möglich',
      ' Gebaut für eine Lebensdauer von 30 Jahren bzw. 8.000 Vollzyklen',
      ' Ladegeschwindigkeit 1 C',
      ' Sicherste Zelltechnologie']);
  }

  getImageUrl(imageName: string, toPrint?: boolean): string {
    if (toPrint) {
      return "assets/img/configurator/290x500/" + imageName;
    } else {
      if (this.vs.detectMob()) {
        return "assets/img/configurator/recommended-product-for-mobile/" + imageName;
      } else if (this.vs.detectTab()) {
        return "assets/img/configurator/recommended-product-for-tablet/" + imageName;
      } else {
        return "assets/img/configurator/" + imageName;
      }
    }
  }


}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { BaseChartDirective } from "ng2-charts";
import { VerificationService } from "src/app/auth/verification/verification.service";

@Component({
  selector: 'end-customer-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnChanges {
  @Input() mainHeading: string = '';
  @Input() subHeading: string = '';
  @Input() value1: number = 0;
  @Input() value2: number = 0;
  @Input() de_heading1!: string;
  @Input() de_heading2!: string;
  @Input() en_heading1!: string;
  @Input() en_heading2!: string;
  batterySwitch: boolean = true;
  @Input() increasedRatio!: number;
  @Input() toBePrinted: boolean = false;
  @Input() color1: string = '';
  @Input() color2: string = '';
  @Output() onBatterySwitchChange: EventEmitter<boolean> = new EventEmitter(false);
  langIsGerman = true;
  @ViewChild(BaseChartDirective) chart!: BaseChartDirective;
  isTablet = false;


  public doughnutChartLabels!: string[];
  public pieChartData!: ChartData<'doughnut'>;
  public options!: ChartOptions;
  constructor(private verificationService: VerificationService) {
    this.isTablet = this.verificationService.detectTab();
  }
  ngOnInit() {
    this.doughnutChartLabels = [
      this.langIsGerman ? this.de_heading1 : this.en_heading1 + this.value1?.toFixed(2) + ' %',
      this.langIsGerman ? this.de_heading2 : this.en_heading2 + this.value2?.toFixed(2) + ' %'
    ];
    this.pieChartData = {
      labels: this.doughnutChartLabels, datasets: [
        {
          data: [this.value1, this.value2],
          backgroundColor: [
            this.color1, this.color2
          ]

        }
      ]

    };
    this.chart?.chart?.update();
    this.options = {
      // cutout: 110,
      elements: {
        arc: {
          borderWidth: 0
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (data) {
              return ' ' + data.formattedValue + '%';
            }
          }
        },
        legend: {
          display: true,
          position: "bottom",
          title: {
            color: "black",
          },
          align: "center",
          labels: {
            color: "black",
            usePointStyle: true,
            font: {
              size: 16
            },
            padding: this.toBePrinted ? 16 : 24,
          }
        }
      },
    };
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.onBatterySwitchChange.emit(this.batterySwitch);
    setTimeout(() => {
      this.updatePieChart();

    }, 100);
  }



  onSwitchChange() {
    this.onBatterySwitchChange.emit(this.batterySwitch);
    setTimeout(() => {
      this.updatePieChart();

    }, 100);
  }

  public updatePieChart() {
    this.pieChartData = this.pieChartData = {
      labels: this.doughnutChartLabels, datasets: [
        {
          data: [this.value1, this.value2],
          backgroundColor: [
            this.color1, this.color2
          ]

        }
      ]

    };
    this.chart?.chart?.update();
  }
}

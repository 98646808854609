<div class="container container-desktop position-relative">

  <!--  <end-customer-portal-configurator-header [pageHeading]="'configurator.step4.heading'"-->
  <!--    [currentStep]="4"></end-customer-portal-configurator-header>-->

  <end-customer-portal-configurator-header [pageHeading]="'configurator.step4.heading'" [currentStep]="4"
    [routeToNextStep]="'/configurator/step4'"
    [isActive]="configurator.configurator.step4UserInteracted"></end-customer-portal-configurator-header>

  <div class="steps w-100">
    <div class="content-wrapper row  justify-content-between">
      <div class="left-content col-7 text-center">
        <div class="d-flex justify-content-center" style="padding-bottom: 3.75rem;">
          <div class="top-section">
            <h1 class="title">{{'configurator.step4.subheading' | translate}}</h1>
            <p class="paragraph">{{'configurator.step4.paragraph' | translate}}</p>
          </div>
        </div>
        <div class="slider-section">
          <p>{{'configurator.step4.slidertitle1' | translate}}</p>
          <div class="custom-slider">
            <ngx-slider class="w-100" [(value)]="configurator.configurator.electricCarChargingStations"
              [options]="options" (valueChange)="userInteracted()"></ngx-slider>
          </div>
          <h1>{{configurator.configurator.electricCarChargingStations}}</h1>
        </div>

        <div class="slider-section">
          <p>{{'configurator.step4.slidertitle2' | translate}}</p>
          <div class="custom-slider">
            <ngx-slider [(value)]="mileageLow" [(highValue)]="mileageHigh" [options]="mileageOptions"
              (valueChange)="bindValue()"></ngx-slider>
          </div>
          <h1 *ngIf="mileageLow != mileageHigh && !(mileageLow == 90 && mileageHigh == 100)">
            {{configurator.configurator.electricCarMileageLow | number :'': 'de-DE'}} -
            {{configurator.configurator.electricCarMileageHigh| number :'': 'de-DE'}} </h1>
          <h1 *ngIf="mileageLow == mileageHigh && mileageLow != 100">
            {{configurator.configurator.electricCarMileageLow | number :'': 'de-DE'}} </h1>
          <h1 *ngIf="mileageLow == 100 && mileageHigh == 100">
            {{configurator.configurator.electricCarMileageHigh| number :'': 'de-DE'}}+ </h1>
        </div>

        <div class="radioOption d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-start justify-content-center gap-2">
            <div class="form-check mt-4 text-start">
              <input class="custom-checkbox me-2" type="checkbox" id="electricCarAccessDrivesMoreThan100000"
                [(ngModel)]="electricCarAccessMax" (ngModelChange)="setSliderToMax()">
              <label class="form-check-label fs18" for="electricCarAccessDrivesMoreThan100000">
                {{'configurator.step4.checkbox1' | translate}}
              </label>
            </div>
            <div class="form-check mt-8 text-start">
              <input class="custom-checkbox me-2" type="checkbox" id="electricCarAccessNoElectricVehicle"
                [(ngModel)]="electricCarAccessMin" (ngModelChange)="setSliderToMin()">
              <label class="form-check-label fs18" for="electricCarAccessNoElectricVehicle">
                {{'configurator.step4.checkbox2' | translate}}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-5 right-content p-0 position-relative">
        <div class="containers d-flex p-0 h-100">
          <!-- <img class="w-100" src="assets/img/configurator/step-4-bg-img.png" alt=""> -->
          <video *ngIf="isDesktop" id="video4" class="w-100 h-100 object-fit-cover overflow-hidden" playsInline>
            <source [src]="videoSrcFw" type="video/mp4">
          </video>

          <video *ngIf="isMobile" id="video4" class="video-content" playsInline>
            <source [src]="videoSrcFwMobile" type="video/mp4">
          </video>
          <video *ngIf="isTablet" id="video4" playsInline>
            <source [src]="videoSrcFwTablet" type="video/mp4">
          </video>
          <!-- <video *ngIf="!isDesktop" id="video4" style="overflow: hidden; object-fit: cover; width: 100%" playsInline>
            <source [src]="videoSrcFwMobile" type="video/mp4">
          </video> -->
          <!-- <video id="video4a" [hidden]="videoSrc == videoSrcFw"
            style="overflow: hidden; object-fit: cover; width: 100%">
            <source [src]="videoSrcBw" type="video/mp4">
          </video> -->
        </div>
        <!--        <end-customer-action-btn [currentStep]="4" [routeToNextStep]="'/configurator/step5'"></end-customer-action-btn>-->
      </div>
    </div>
  </div>
  <end-customer-portal-configurator-footer [routeToNextStep]="'configurator/step5'" [currentStep]="4"
    [isActive]="configurator.configurator.step4UserInteracted"></end-customer-portal-configurator-footer>
</div>
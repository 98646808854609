<div class="container container-desktop position-relative">

  <end-customer-portal-configurator-header [pageHeading]="'configurator.step3.heading'" [currentStep]="3"
    [routeToNextStep]="'/configurator/step4'"
    [isActive]="configurationService.configurator.step3UserInteracted"></end-customer-portal-configurator-header>
  <div class="steps w-100">
    <div class="content-wrapper row justify-content-between">
      <div class="left-content col-7 text-center">
        <div class="d-flex justify-content-center">
          <div class="top-section">
            <h1 class="title">{{'configurator.step3.subheading1' | translate}}</h1>
            <p class="paragraph">{{'configurator.step3.paragraph' | translate}}</p>
          </div>
        </div>
        <div class="slider-section">
          <p>{{'configurator.step3.subheading2' | translate}}</p>
          <div class="custom-slider w-100">
            <ngx-slider [(value)]="configurationService.configurator.PVSystemPower" [options]="options"
              (valueChange)="configurationService.configurator.step3UserInteracted = true;unsetNoPVForPositiveVal()"></ngx-slider>
          </div>
          <h1>{{configurationService.configurator.PVSystemPower}} kWp</h1>
        </div>
        <div class="other-opinion d-flex align-items-center justify-content-center">
          <div class="d-flex align-items-start flex-column justify-content-left gap-2">
            <div class="form-check text-start">
              <input class="custom-checkbox me-2" type="checkbox" [(ngModel)]="dontHavePV" id="pvAccessDontHave"
                (ngModelChange)="getSelectedItem()"
                (change)="configurationService.configurator.PVSystemPower = 0;configurationService.configurator.step3UserInteracted = true;">
              <label class=" form-check-label fs18" for="pvAccessDontHave">
                {{'configurator.step3.option1' |translate}}
              </label>
            </div>
            <div class="form-check text-start">
              <input class="custom-checkbox me-2" type="checkbox" [(ngModel)]="planningForPV" id="pvAccessPlanning"
                (ngModelChange)="getSelectedItem()"
                (change)="configurationService.configurator.step3UserInteracted = true;">
              <label class="form-check-label fs18" for="pvAccessPlanning">
                {{'configurator.step3.option2' |translate}}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-5 right-content position-relative p-0">
        <div class="containers  d-flex p-0 h-100">
          <video *ngIf="isDesktop" id="video3" class="w-100 h-100 object-fit-cover overflow-hidden" playsInline>
            <source [src]="videoSrcFw" type="video/mp4">
          </video>
          <video *ngIf="isMobile" id="video3" class="video-content" playsInline>
            <source [src]="videoSrcFwMobile" type="video/mp4">
          </video>
          <video *ngIf="isTablet" id="video3" playsInline>
            <source [src]="videoSrcFwTablet" type="video/mp4">
          </video>
        </div>
        <!--        <end-customer-action-btn [currentStep]="3" [routeToNextStep]="'/configurator/step4'"></end-customer-action-btn>-->

      </div>
    </div>
  </div>

  <div>

  </div>
  <end-customer-portal-configurator-footer [routeToNextStep]="'configurator/step4'" [currentStep]="3"
    [isActive]="configurationService.configurator.step3UserInteracted"></end-customer-portal-configurator-footer>
</div>
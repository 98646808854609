import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from "@azure/msal-browser";
import { HomeComponent } from "./home/home.component";

const routes: Routes = [
  {
    path: 'configurator',
    loadChildren: () => import('./configurator/configurator.module').then(m => m.ConfiguratorModule),
    // canActivate: [MsalGuard]
  }, {
    path: 'ees',
    loadChildren: () => import('./ees/ees.module').then(m => m.EESModule),
    // canActivate: [MsalGuard]
  },

  {
    path: 'home', component: HomeComponent
  }

];


const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? "enabledNonBlocking"
          : "disabled", // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { UUID } from "angular2-uuid";
import { simulationResponse } from "src/app/core/models/simulationResponse.model";
import { AppConstants } from "../../core/constants";
import { DataShareService } from "../../services/data-share.service";
import { BusinessArea } from "./business-area.enum";
import { GuestConfiguration } from "./guest-configuration.model";

@Injectable({
    providedIn: 'root'
}) export class ConfiguratorService {
    constructor(private translationService: TranslateService,
        private router: Router,
        private http: HttpClient,
        private dataShareService: DataShareService
    ) { }

    public configurator!: GuestConfiguration;
    public simulationResponse!: simulationResponse;
    public isRunningInWindow: boolean = false;
    public stepToShowInWindow: number = 0;
    public step1Entry!: number;
    public step2Entry!: number;
    public step3Entry!: number;
    public step4Entry!: number;
    public step5Entry!: number;
    public step6Entry!: number;
    public step1Exit!: number;
    public step2Exit!: number;
    public step3Exit!: number;
    public step4Exit!: number;
    public step5Exit!: number;
    public step6Exit!: number;

    public monthlyGridEnergyData!: [];
    public photovoltaicEnergyData!: [];
    public totalElectricityDiff = 0;
    public increasedSelfSufficiencyRatio = 0;
    public increasedSelfConsumptionRatio = 0;
    businessSector!: string;
    application1: string | undefined;
    application2: string | undefined;
    application3: string | undefined;


    startConfiguration() {
        this.configurator = {
            id: UUID.UUID(),
            language: this.translationService.currentLang,
            step1UserInteracted: false,
            step2UserInteracted: false,
            step3UserInteracted: false,
            step4UserInteracted: false,
            step5UserInteracted: false,
            step6UserInteracted: false,
            PVSystemAccess: [],
            PVSystemPower: 20,
            application: [],
            businessArea: BusinessArea.smallBusiness,
            businessAreaSomethingElse: undefined,
            electricCarChargingStations: 2,
            electricCarAccess: undefined,
            electricCarMileageHigh: 30000,
            electricCarMileageLow: 10000,
            electricityConsumption: 50000,
            entryPoint: 0,
            step1Duration: 0,
            step2Duration: 0,
            step3Duration: 0,
            step4Duration: 0,
            step5Duration: 0,
            step6Duration: 0,
            knowledgeLevel: 3,
            knowledgeOption: undefined,
            applicationSomethingElse: undefined
        };
        this.step1Entry = new Date().getTime();
    }

    addStepDuration(currentStep: number) {
        switch (currentStep) {
            case 2:
                this.step2Exit = new Date().getTime();
                this.step1Entry = new Date().getTime();
                this.configurator.step2Duration += this.step2Exit - this.step2Entry;
                break;
            case 3:
                this.step3Exit = new Date().getTime();
                this.step2Entry = new Date().getTime();
                this.configurator.step3Duration += this.step3Exit - this.step3Entry;
                break;
            case 4:
                this.step4Exit = new Date().getTime();
                this.step3Entry = new Date().getTime();
                this.configurator.step4Duration += this.step4Exit - this.step4Entry;
                break;
            case 5:
                this.step5Exit = new Date().getTime();
                this.step4Entry = new Date().getTime();
                this.configurator.step5Duration += this.step5Exit - this.step5Entry;
                break;
            case 6:
                this.step6Exit = new Date().getTime();
                this.step5Entry = new Date().getTime();
                this.configurator.step6Duration += this.step6Exit - this.step6Entry;
                break;
        }
    }


    goToPreviousStep(currentStep: number) {
        this.dataShareService.scrollToNav.next();
        this.addStepDuration(currentStep);

        if (this.isRunningInWindow) {
            this.stepToShowInWindow--;
        } else {
            if (currentStep > 1) {
                currentStep--;
                this.router.navigate([`configurator/step${currentStep}`]);

            }
        }
    }
    goToNextStep(currentStep: number, routeToNextStep: any) {
        this.dataShareService.scrollToNav.next();
        switch (currentStep) {
            case 1: this.step1Exit = new Date().getTime();
                this.step2Entry = new Date().getTime();
                this.configurator.step1Duration += this.step1Exit - this.step1Entry;
                break;
            case 2: this.step2Exit = new Date().getTime();
                this.step3Entry = new Date().getTime();
                this.configurator.step2Duration += this.step2Exit - this.step2Entry;
                break;
            case 3: this.step3Exit = new Date().getTime();
                this.step4Entry = new Date().getTime();
                this.configurator.step3Duration += this.step3Exit - this.step3Entry;
                break;
            case 4: this.step4Exit = new Date().getTime();
                this.step5Entry = new Date().getTime();
                this.configurator.step4Duration += this.step4Exit - this.step4Entry;
                break;
            case 5: this.step5Exit = new Date().getTime();
                this.step6Entry = new Date().getTime();
                this.configurator.step5Duration += this.step5Exit - this.step5Entry;
                break;
            case 6: this.step6Exit = new Date().getTime();
                this.configurator.step6Duration += this.step6Exit - this.step6Entry;
                break;
        }
        if (this.isRunningInWindow == false) {
            if (currentStep < 6) {
                this.router.navigate([routeToNextStep]);
            }
            else {
                this.callAPI(this.isRunningInWindow)
                // this.router.navigate([routeToNextStep]);// don't commit

            }
        } else {
            if (this.stepToShowInWindow < 6) {
                this.stepToShowInWindow += 1;
            } else {
                // this.stepToShowInWindow += 1; // don't commit
                this.callAPI(this.isRunningInWindow)

            }
        }
    }

    callAPI(nextStepInside: boolean) {
        this.dataShareService.spinnerText = this.translationService.instant('loadertext');
        this.dataShareService.callCountForLoader.next(1);
        this.http.post(AppConstants.API_URL + 'Configurator/saveconfiguratordata', this.configurator)
            .subscribe((data: any) => {
                this.dataShareService.spinnerText = '';
                {
                    // Set your parameters
                    const projectId = data;
                    const requestedBy = 'ecj';
                    let application = "SelfConsumptionOptimization";
                    // removed as per using only self consumption for firt release
                    // switch (this.configurator.application.length) {
                    //     case 1:
                    //         application = this.configurator.application[0] == 0 ? "PeakLoadShaving" : this.configurator.application[0] == 1 ? "SelfConsumptionOptimization" : null;
                    //         break;
                    //     case 2:
                    //     case 3:
                    //         application = "MultiUse";
                    //         break;
                    // }

                    let consumptionProfileName;
                    switch (this.configurator.businessArea) {
                        case BusinessArea.smallBusiness:
                            consumptionProfileName = 'G1';
                            break;
                        case BusinessArea.logistics:
                            consumptionProfileName = 'G3';
                            break;
                        case BusinessArea.industry:
                            consumptionProfileName = 'G0';
                            break;
                        case BusinessArea.farming:
                            consumptionProfileName = 'L1';
                            break;
                        default:
                            consumptionProfileName = 'G1';
                            break;

                    }
                    const peakPower = this.configurator.PVSystemPower == 0 ? 1 : this.configurator.PVSystemPower;
                    const annualConsumption = this.configurator.electricityConsumption;

                    // Construct the URL with query parameters
                    const apiUrl = AppConstants.API_URL + 'Configurator/createsimulation';
                    const urlWithParams = `${apiUrl}?projectId=${projectId}&requestedBy=${requestedBy}&application=${application}&consumptionProfileName=${consumptionProfileName}&peakPower=${peakPower}&annualConsumption=${annualConsumption}`;

                    // Make the API call
                    this.http.get(urlWithParams).subscribe(
                        (response) => {
                            this.dataShareService.callCountForLoader.next(0);

                            this.dataShareService.sendSimulationDataSubject.next(response);
                            if (nextStepInside) {
                                this.stepToShowInWindow += 1;

                            } else {
                                this.router.navigate(['configurator/product-suggestion']);
                            }// add navigation
                        },
                        (error) => {
                            console.error(error);
                        }
                    );
                }
            });
    }
    modifyChartData(data: any): any {
        this.monthlyGridEnergyData = data.MonthlyGridEnergy;
        this.photovoltaicEnergyData = data.PhotovoltaicEnergyUse;
        this.getTotalSaving(this.monthlyGridEnergyData);
    }
    getTotalSaving(data: any) {
        let sumWithBattery = 0;
        let sumWithoutBattery = 0;
        data.WithOutBattery.forEach((val: number) => sumWithoutBattery += val);
        data.WithBattery.forEach((val: number) => sumWithBattery += val);
        this.totalElectricityDiff = sumWithoutBattery - sumWithBattery;
    }

    getConfigurationById(guid: string) {
        return this.http.get(AppConstants.API_URL + 'Configurator/simulation-detailed-data?configurationId=' + guid);
    }

    getReportParamValue(startDate: Date, endDate: Date) {
        return this.http.post<{ key: string, value: number }>(AppConstants.API_URL + 'Configurator/internal-data', { startDate: startDate.toISOString(), endDate: endDate.toISOString() });
    }
    getReportParamValueBubbleGraph1(startDate: Date, endDate: Date) {
        return this.http.post<Array<{ businessArea: string, pvSystemPower: number, electricityConsumption: number, userInterest: number }>>(AppConstants.API_URL + 'Configurator/internal-data-bubble-graph-consumption-vs-pv', { startDate: startDate.toISOString(), endDate: endDate.toISOString() });
    }
    getReportParamValueBubbleGraph2(startDate: Date, endDate: Date) {
        return this.http.post<Array<{ businessArea: string, pvSystemPower: number, step1Duration: number, step2Duration: number, step3Duration: number, step4Duration: number, step5Duration: number, step6Duration: number }>>(AppConstants.API_URL + 'Configurator/internal-data-bubble-graph-duration-vs-pv', { startDate: startDate.toISOString(), endDate: endDate.toISOString() });
    }
}

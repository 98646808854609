<div class="config-header config-header-desktop w-100">
  <div class="btn-wrapper">
    <div class="row align-items-center justify-content-center">
      <div class="col-3 text-start" *ngIf="currentStep >= 2">
        <button (click)="configuratorService.goToPreviousStep(currentStep)"
          class="btn btn-light prevNextBtn float-start d-flex justify-content-center align-items-center gap-3">
          <img class="" src="/assets/img/left-arrow.svg" alt="">
          {{'prevBtnTitle' | translate}}</button>
      </div>
      <div class="col-3 text-start" *ngIf="currentStep < 2"></div>

      <div class="col-6 text-center">
        <h1 class="text-center">{{pageHeading | translate}}</h1>
      </div>

      <div class="col-3 text-end" *ngIf="currentStep >= 1">
        <button (click)="configuratorService.goToNextStep(currentStep,this.getNextStepUrl() )"
          class="btn btn-light prevNextBtn float-end d-flex justify-content-center align-items-center gap-4p"
          [ngClass]="{'active': isActive, 'bigBtn': currentStep == 6}">
          {{ (currentStep === 6 ? 'resultBtnTitle' : 'nextBtnTitle') | translate }}
          <img class="" src="/assets/img/right-arrow.svg" alt="">
        </button>
      </div>
      <!--     #ToDo:Hotfix-->
      <div class="col-3 text-end"></div>
    </div>
  </div>


  <div class="d-flex align-items-center progress-parent justify-content-center" style="width: 100%;">
    <div class="position-relative custom-progress-bar ">
      <img [src]="getStepImage(1)" alt="">
      <h3 [style.color]="getStepTextColor(1)">{{'configurator.businesssector' | translate}}</h3>
    </div>

    <div class="dash">
      <img src="assets/img/configurator/line.png" alt="">
    </div>

    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(2)" alt="">
      <h3 [style.color]="getStepTextColor(2)">{{'configurator.powerconsumption' | translate}}</h3>
    </div>

    <div class="dash">
      <img src="assets/img/configurator/line.png" alt="">
    </div>

    <div class="position-relative custom-progress-bar ">
      <img [src]="getStepImage(3)" alt="">
      <h3 [style.color]="getStepTextColor(3)">{{'configurator.pvsystem' | translate}}</h3>
    </div>

    <div class="dash">
      <img src="assets/img/configurator/line.png " alt="">
    </div>

    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(4)" alt="">
      <h3 [style.color]="getStepTextColor(4)">{{'configurator.electricvehicles' | translate}}</h3>
    </div>
    <div class="dash">
      <img src="assets/img/configurator/line.png" alt="">
    </div>

    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(5)" alt="">
      <h3 [style.color]="getStepTextColor(5)">{{'configurator.storagesystem' | translate}}</h3>
    </div>

    <div class="dash">
      <img src="assets/img/configurator/line.png" alt="">
    </div>

    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(6)" alt="">
      <h3 [style.color]="getStepTextColor(6)">{{'configurator.application' | translate}}</h3>
    </div>


  </div>

</div>

<div class="config-header config-header-mobile w-100 d-none">
  <div class="row  align-items-center">
    <div class="col-12">
      <h1 class="text-center headingTitle" style="font-size: 12px">{{pageHeading2 | translate}}</h1>
    </div>
  </div>


  <div class="d-flex align-items-center justify-content-center progress-parent">

    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(1, 'mobile')" alt="">
    </div>


    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(2, 'mobile')" alt="">
    </div>

    <div class="position-relative custom-progress-bar ">
      <img [src]="getStepImage(3, 'mobile')" alt="">
    </div>


    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(4, 'mobile')" alt="">
    </div>

    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(5, 'mobile')" alt="">
    </div>

    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(6, 'mobile')" alt="">
    </div>


  </div>

</div>

<div class="config-header config-header-tablet w-100">
  <div class="row  align-items-center">
    <div class="col-12">
      <h1 class="text-center headingTitle">{{pageHeading | translate}}</h1>
    </div>

  </div>


  <div class="d-flex justify-content-center progress-parent">

    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(1, 'tablet')" alt="">
    </div>

    <div class="dash">
      <img src="assets/img/configurator/line.png" alt="">
    </div>

    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(2, 'tablet')" alt="">
    </div>

    <div class="dash">
      <img src="assets/img/configurator/line.png" alt="">
    </div>

    <div class="position-relative custom-progress-bar ">
      <img [src]="getStepImage(3, 'tablet')" alt="">
    </div>

    <div class="dash">
      <img src="assets/img/configurator/line.png " alt="">
    </div>

    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(4, 'tablet')" alt="">
    </div>
    <div class="dash">
      <img src="assets/img/configurator/line.png" alt="">
    </div>

    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(5, 'tablet')" alt="">
    </div>

    <div class="dash">
      <img src="assets/img/configurator/line.png" alt="">
    </div>

    <div class="position-relative custom-progress-bar">
      <img [src]="getStepImage(6, 'tablet')" alt="">
    </div>


  </div>

</div>
import { Component, Input } from "@angular/core";
import { ConfiguratorService } from "../utility/configurator.service";

@Component({
    selector: 'end-customer-portal-configurator-footer',
    templateUrl: './configurator-footer.component.html',
    styleUrls: ['./configurator-footer.component.scss']
})
export class ConfiguratorFooterComponent {
    @Input() routeToNextStep!: any;
    @Input() isActive!: boolean;
    @Input() currentStep!: number;

    constructor(public configuratorService: ConfiguratorService
    ) {
    }
}
<div class="sidebar" [ngClass]="{ 'expanded': isExpanded }">
    <div class="header" (click)="handleSidebarToggle()">
        <img src="assets/united-kingdom.svg" class="rounded-circle user-img">
        <h1 class="title" *ngIf="isExpanded">
            Company Name
        </h1>
        <!-- <div class="toggle">
            <i class="fa fa-arrow-right" *ngIf="!isExpanded" aria-hidden="true"></i>
            <i class="fa fa-arrow-left" *ngIf="isExpanded" aria-hidden="true"></i>
        </div> -->
    </div>
    <div class="content">
        <div class="link">
            <div class="content flex-div" [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
                <div class="icon">
                    <i class="fa fa-user" aria-hidden="true"></i>
                </div>
                <h6 *ngIf="isExpanded">{{"sidenav.home" | translate}}</h6>
            </div>
        </div>
        <div class="link with-children accordion" id="accordionPersonalInfo">
            <div class="content accordion-item" data-bs-toggle="collapse" data-bs-target="#collapsePersonalInfo">
                <!-- <div class="icon">
                    <i class="fa fa-user" aria-hidden="true" ></i>
                </div> -->
                <h6 class="group-heading" *ngIf="!isExpanded">YOU</h6>

                <h6 class="group-heading" *ngIf="isExpanded">{{ 'sidenav.personalinformation'| translate | uppercase}}
                </h6>
                <!-- <i                    class="fa fa-chevron-up" aria-hidden="true"></i> -->
            </div>
            <div class="children accordion-collapse collapse show" id="collapsePersonalInfo"
                data-bs-parent="#accordionPersonalInfo">
                <div class="link">
                    <div class="content" [routerLink]="['/configuration']" [routerLinkActive]="'active'">
                        <div class="icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <h6 *ngIf="isExpanded">{{"sidenav.myprofile" | translate}}</h6>
                    </div>
                </div>
                <div class="link">
                    <div class="content" [routerLink]="['/configuration']" [routerLinkActive]="'active'">
                        <div class="icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <h6 *ngIf="isExpanded">{{"sidenav.mycompany" | translate}}</h6>
                    </div>
                </div>
                <div class="link">
                    <div class="content" [routerLink]="['/configuration']" [routerLinkActive]="'active'">
                        <div class="icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <h6 *ngIf="isExpanded">{{"sidenav.myprojects" | translate}}</h6>
                    </div>
                </div>
                <div class="link">
                    <div class="content" [routerLink]="['/documents']" [routerLinkActive]="'active'">
                        <div class="icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <h6 *ngIf="isExpanded">{{"sidenav.mydocuments" | translate}}</h6>
                    </div>
                </div>
                <div class="link">
                    <div class="content" [routerLink]="['/configuration']" [routerLinkActive]="'active'">
                        <div class="icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <h6 *ngIf="isExpanded">{{"sidenav.settings" | translate}}</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="link with-children accordion" id="accordionInfoHub">
            <div class="content accordion-item" data-bs-toggle="collapse" data-bs-target="#collapseInfo">
                <!-- <div class="icon">
                    <i class="fa fa-user" aria-hidden="true" ></i>
                </div> -->
                <h6 class="group-heading" *ngIf="!isExpanded">INFO</h6>

                <h6 class="group-heading" *ngIf="isExpanded">{{ 'sidenav.informationhub'| translate | uppercase}}</h6>
            </div>
            <div class="children accordion-collapse collapse" id="collapseInfo" data-bs-parent="#accordionInfoHub">
                <div class="link">
                    <div class="content" [routerLink]="['/configuration']" [routerLinkActive]="'active'">
                        <div class="icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <h6 *ngIf="isExpanded">{{"sidenav.myinbox" | translate}}</h6>
                    </div>
                </div>
                <div class="link">
                    <div class="content" [routerLink]="['/configuration']" [routerLinkActive]="'active'">
                        <div class="icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <h6>{{"sidenav.usecases" | translate}}</h6>
                    </div>
                </div>
                <div class="link">
                    <div class="content" [routerLink]="['/media']" [routerLinkActive]="'active'">
                        <div class="icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <h6>{{"sidenav.media" | translate}}</h6>
                    </div>
                </div>
                <div class="link">
                    <div class="content" [routerLink]="['/help']" [routerLinkActive]="'active'">
                        <div class="icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <h6>{{"sidenav.support" | translate}}</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="link with-children accordion" id="accordionPersonalServices">
            <div class="content accordion-item" data-bs-toggle="collapse" data-bs-target="#collapseServices">
                <!-- <div class="icon">
                    <i class="fa fa-user" aria-hidden="true" ></i>
                </div> -->
                <h6 class="group-heading" *ngIf="!isExpanded">TESVOLT</h6>
                <h6 class="group-heading" *ngIf="isExpanded">{{ 'sidenav.tesvoltproductsandservices'| translate |
                    uppercase}}</h6>
            </div>
            <div class="children accordion-collapse collapse" id="collapseServices" data-bs-parent="#accordionServices">
                <div class="link">
                    <div class="content" [routerLink]="['/configuration']" [routerLinkActive]="'active'">
                        <div class="icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <h6>{{"sidenav.powerstoragesolution" | translate}}</h6>
                    </div>
                </div>
                <div class="link">
                    <div class="content" [routerLink]="['/configuration']" [routerLinkActive]="'active'">
                        <div class="icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <h6>{{"sidenav.applications" | translate}}</h6>
                    </div>
                </div>
                <div class="link">
                    <div class="content" [routerLink]="['/configuration']" [routerLinkActive]="'active'">
                        <div class="icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <h6>{{"sidenav.ems" | translate}}</h6>
                    </div>
                </div>
                <div class="link">
                    <div class="content" [routerLink]="['/configuration']" [routerLinkActive]="'active'">
                        <div class="icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <h6>HYVE</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="link">
            <div class="content flex-div" (click)="handleSidebarToggle()">
                <i class="fa fa-user" aria-hidden="true"></i>
                <h6>{{"sidenav.collapse" | translate}}</h6>
            </div>
        </div>
    </div>
</div>
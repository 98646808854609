import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { NgbCarousel, NgbSlideEvent } from "@ng-bootstrap/ng-bootstrap/carousel/carousel";
import { filter } from 'rxjs/operators';
import { DataShareService } from "src/app/services/data-share.service";
import { simulationResponse } from '../../core/models/simulationResponse.model';
import { BusinessArea } from "../utility/business-area.enum";
import { ConfiguratorService } from '../utility/configurator.service';
import { UserService } from "../../services/user.service";
import { TranslateService } from "@ngx-translate/core";
import { VerificationService } from "src/app/auth/verification/verification.service";

@Component({
  selector: 'end-customer-product-suggestion',
  templateUrl: './product-suggestion-lite.component.html',
  styleUrls: ['./product-suggestion-lite.component.scss']
})
export class ProductSuggestionComponent implements OnChanges {
  @ViewChild('closeButton') closeButton !: ElementRef;


  @Input() visible!: boolean;
  showchart = true;
  score = 0;
  interest = "";
  videoLink: string = '';
  showVideo = false;
  quantity: number = 1;
  simuplexResponse!: simulationResponse;
  productRecommendationTabIsSelected: boolean = false;
  userInteracted: boolean = false;
  selfSufficiencyVal = 0;
  interval = 7000;
  @ViewChild('carousel') carousel!: NgbCarousel;
  currentSlideTitle: string = '';
  graphTitle: string = "configurator.successPage.slide1Title"
  recommendedProductTitle: string = "configurator.successPage.slide2Title"
  userInputTitle: string = "configurator.successPage.slide3Title"
  isPortrait = false;
  isDesktop = true;
  isMobile = true;
  isTablet = true;
  increasedSelfSufficiencyRatio = 0;
  increasedSelfConsumptionRatio = 0;
  directConsumption: number = 0;
  gridFeedInPortion: number = 0;
  selfSufficiencyRatio: number = 0;
  imgAddress = "assets/under_construction_01.jpg ";
  imgAddressMob = "assets/no_application_selection_mobile_01.jpg";
  constructor(
    public configuratorService: ConfiguratorService,
    public dataService: DataShareService,
    private router: Router,
    public userService: UserService,
    private translationService: TranslateService,
    private verificationService: VerificationService
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.initialise();
      }
    });
    this.translationService.onLangChange.subscribe(() => {
      if (this.carousel) {
        this.getTranslatedTitle(this.carousel.activeId);
      }
    })
    this.isMobile = this.verificationService.detectMob();
    this.isTablet = this.verificationService.detectTab();
    this.isDesktop = !this.isMobile && !this.isTablet;
    this.isPortrait = window.innerHeight > window.innerWidth;
    // this.increasedSelfSufficiencyRatio = this.configuratorService.increasedSelfSufficiencyRatio;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['visible'].currentValue == true) {
      this.initialise();
      switch (this.configuratorService.configurator?.businessArea) {
        case BusinessArea.smallBusiness:
          this.configuratorService.businessSector = "Small Business";
          break;
        case BusinessArea.logistics:
          this.configuratorService.businessSector = "Logistics";
          break;
        case BusinessArea.industry:
          this.configuratorService.businessSector = "Industry";
          break;
        case BusinessArea.farming:
          this.configuratorService.businessSector = "Farming";
          break;
        default:
          this.configuratorService.businessSector = this.configuratorService.configurator?.businessAreaSomethingElse?.toString() ?? '-';
          break;
      };
      this.configuratorService.application1 = this.configuratorService.configurator?.application[0] == 0 ? "Peak Load Shaving" : this.configuratorService.configurator?.application[0] == 1 ? "Self Consumption Optimization" : this.configuratorService.configurator?.application[0] == 2 ? "Backup Power" : this.configuratorService.configurator?.applicationSomethingElse?.toString() ?? undefined;
      this.configuratorService.application2 = this.configuratorService.configurator?.application[1] == 0 ? "Peak Load Shaving" : this.configuratorService.configurator?.application[1] == 1 ? "Self Consumption Optimization" : this.configuratorService.configurator?.application[1] == 2 ? "Backup Power" : this.configuratorService.configurator?.applicationSomethingElse?.toString() ?? undefined;
      this.configuratorService.application3 = this.configuratorService.configurator?.application[2] == 0 ? "Peak Load Shaving" : this.configuratorService.configurator?.application[2] == 1 ? "Self Consumption Optimization" : this.configuratorService.configurator?.application[2] == 2 ? "Backup Power" : this.configuratorService.configurator?.applicationSomethingElse?.toString() ?? undefined;

    }
  }

  initialise(): void {
    // dummy data
    // let data = {
    //   message: "Simulation Generated Successfully",
    //   errorMessage: "",
    //   status: 200,
    //   result: {
    //     projectId: "6624d14c-cd91-007d-70ca-83d9ca0770cc",
    //     productId: 2008,
    //     productName: "TS HV 30-80 E",
    //     productImage: "tshv30e-50e-80e_v2_floor.png",
    //     quantity: 1,
    //     selfConsumptionRatio: 40.011478445250745,
    //     selfSufficiencyRatio: 66.67548898402414,
    //     annualConsumption: 120000,
    //     capacity: 112,
    //     power: 30,
    //     numberOfInverters: 1,
    //     inverterName: "STPS X 30",
    //     systemVariation: "TS HV 30-80 E",
    //     gridSupply: 39989,
    //     gridFeedIn: -122436,
    //     simulationData: {
    //       MonthlyGridEnergy: {
    //         WithBattery: [8283, 5870.26, 3113.24, 608.61, 111.04, 210.32, 288.03, 308.79, 1015.37, 4344.57, 8198.56, 7636.60],
    //         WithOutBattery: [8822.30, 6546.79, 4079.56, 1593.62, 1085.64, 1098.42, 1126.13, 1299.51, 2077.43, 5196.70, 8640.54, 8136.29]
    //       },
    //       PhotovoltaicEnergyUse: {
    //         WithBattery: { GridFeedInPortion: 18, DirectConsumption: 82, SelfSufficiencyRatio: 66.67548898402414 },
    //         WithOutBattery: { GridFeedInPortion: 40, DirectConsumption: 60, SelfSufficiencyRatio: 58.58083434844299 }
    //       }
    //     }
    //   }
    // }

    this.dataService.sendSimulationDataObservable.subscribe((data: any) => {
      if (data.result != null) {
        this.configuratorService.simulationResponse = data.result;
        this.simuplexResponse = { ...data.result };
        this.simuplexResponse.annualConsumption = data.result.annualConsumption;
        this.simuplexResponse.capacity = data.result.capacity;
        this.simuplexResponse.inverterName = data.result.inverterName;
        this.simuplexResponse.numberOfInverters = data.result.numberOfInverters;
        this.simuplexResponse.power = data.result.power;
        this.simuplexResponse.productId = data.result.productId;
        this.simuplexResponse.productImage = data.result.productImage;
        this.simuplexResponse.productName = data.result.productName;
        this.simuplexResponse.projectId = data.result.projectId;
        this.simuplexResponse.quantity = data.result.quantity;
        this.simuplexResponse.selfConsumptionRatio = data.result.selfConsumptionRatio;
        this.simuplexResponse.selfSufficiencyRatio = data.result.selfSufficiencyRatio;
        if (typeof data.result.simulationData == 'string') {
          data.result.simulationData = JSON.parse(data.result.simulationData); // uncomment line while commiting
        }
        let increasedSSPoint = data.result.simulationData.PhotovoltaicEnergyUse.WithBattery.SelfSufficiencyRatio - data.result.simulationData.PhotovoltaicEnergyUse.WithOutBattery.SelfSufficiencyRatio;
        this.configuratorService.increasedSelfSufficiencyRatio = increasedSSPoint / data.result.simulationData.PhotovoltaicEnergyUse.WithOutBattery.SelfSufficiencyRatio * 100;
        this.increasedSelfSufficiencyRatio = this.configuratorService.increasedSelfSufficiencyRatio;

        let increasedSCPoint = data.result.simulationData.PhotovoltaicEnergyUse.WithBattery.DirectConsumption - data.result.simulationData.PhotovoltaicEnergyUse.WithOutBattery.DirectConsumption;
        this.configuratorService.increasedSelfConsumptionRatio = increasedSCPoint / data.result.simulationData.PhotovoltaicEnergyUse.WithOutBattery.DirectConsumption * 100;
        this.increasedSelfConsumptionRatio = this.configuratorService.increasedSelfConsumptionRatio;

        this.configuratorService.modifyChartData(data.result.simulationData);
        this.getSelfSufficiencyRatioChartData(true);
        this.getSelfConsumptionChartData(true);

      }
    });
    this.currentSlideTitle = this.translationService.instant(this.graphTitle);

  }

  getTranslatedTitle(currentEventName: string) {
    if (currentEventName == 'graph' || currentEventName == 'graph1' || currentEventName == 'graph2') {
      this.currentSlideTitle = this.translationService.instant(this.graphTitle)
    }
    if (currentEventName == 'data') {
      this.currentSlideTitle = this.translationService.instant(this.recommendedProductTitle) + ': ' + this.simuplexResponse.productName
    }

    if (currentEventName == 'inputs') {
      this.currentSlideTitle = this.translationService.instant(this.userInputTitle)
    }

  }

  onSlide(event: NgbSlideEvent) {
    if (event.current == 'graph' || event.current == 'graph1' || event.current == 'graph2') {
      this.productRecommendationTabIsSelected = false;
    } else {
      this.productRecommendationTabIsSelected = true;
    }
    if (this.userInteracted == false && (event.source == 'arrowRight' || event.source == 'arrowLeft')) {
      this.userInteracted = true;
      this.interval = 10000;
    }
    this.getTranslatedTitle(event.current);
  }

  toggleSelectedTab(n: number) {
    this.userInteracted = true;
    this.interval = 10000;
    let temp = JSON.parse(JSON.stringify(this.productRecommendationTabIsSelected));
    if (n == 1) {
      this.productRecommendationTabIsSelected = false;
    } else {
      this.productRecommendationTabIsSelected = true;
    }
    if (temp != this.productRecommendationTabIsSelected) {
      this.carousel.next();
    }
  }
  showInternalReport() {
    this.showchart = false;
  }

  navigateToSignUp() {
    this.router.navigate(['auth', 'sign-up']);
  }

  openMarketingForm() {
    this.router.navigate(['configurator', 'marketing-contact-form']);
  }

  redirectToPage() {
    this.dataService.scrollToNav.next();
    if (this.userService.loggedInUser) {
      this.router.navigate(['configurator', 'acknowledgement']);
    }
    else {
      this.router.navigate(['configurator', 'marketing-contact-form']);
    }
  }

  restartConfiguration() {
    this.dataService.scrollToNav.next();
    this.configuratorService.startConfiguration();
    this.configuratorService.stepToShowInWindow = 0;
    this.closeButton.nativeElement.click();
    this.router.navigate(['/home']);
  }

  getSelfConsumptionChartData(withBattery: boolean) {
    let finalChartData = [];
    if (withBattery) {
      finalChartData = ((this.configuratorService.photovoltaicEnergyData) as any)?.WithBattery;
    } else {
      finalChartData = ((this.configuratorService.photovoltaicEnergyData) as any)?.WithOutBattery;
    }
    this.directConsumption = finalChartData.DirectConsumption;
    this.gridFeedInPortion = finalChartData.GridFeedInPortion;

  }

  getSelfSufficiencyRatioChartData(withBattery: boolean) {
    let finalChartData = [];
    if (withBattery) {
      finalChartData = ((this.configuratorService.photovoltaicEnergyData) as any)?.WithBattery;
    } else {
      finalChartData = ((this.configuratorService.photovoltaicEnergyData) as any)?.WithOutBattery;
    }
    this.selfSufficiencyRatio = finalChartData.SelfSufficiencyRatio;
  }

}

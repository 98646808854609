<div class="inputTableWrapper d-flex flex-row justify-content-center align-items-center"
    [ngClass]="!toBePrinted ? 'p-5':''">
    <div class="left-side col-lg-6 col-md-12">
        <div [ngClass]="!toBePrinted ?'mx-auto':''" class="table1-wrapper">
            <table class="my-4" id="table1">
                <tr>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"><span><svg width="24" height="24"
                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 12L10 17L20 7" stroke="#768F1C" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </span></td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('businessArea')" (click)="setImageTag('businessArea')" translate>
                        Business sector:</td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('businessArea')" (click)="setImageTag('businessArea')" translate>
                        {{businessSector}}</td>
                </tr>
                <tr>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"><span><svg width="24" height="24"
                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 12L10 17L20 7" stroke="#768F1C" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </span></td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('powerConsumption')" (click)="setImageTag('powerConsumption')"
                        translate>
                        Power consumption:</td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('powerConsumption')" (click)="setImageTag('powerConsumption')">
                        {{(configuratorService.configurator ? configuratorService.configurator.electricityConsumption
                        :1)
                        | number:'1.0-2':'de-DE'}} kWh/Jahr</td>
                </tr>
                <tr>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"><span><svg width="24" height="24"
                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 12L10 17L20 7" stroke="#768F1C" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </span></td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('powerGeneration')" (click)="setImageTag('powerGeneration')"
                        translate>
                        Power generation:</td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('powerGeneration')" (click)="setImageTag('powerGeneration')">
                        {{configuratorService.configurator ? (configuratorService.configurator.PVSystemPower == 0 ||
                        configuratorService.configurator.PVSystemPower == null ?
                        'Keine PV' : (configuratorService.configurator.PVSystemPower | number:'1.0-2':'de-DE') + 'kWp'
                        ):'Keine PV' }} </td>
                </tr>
                <tr>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"><span><svg width="24" height="24"
                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 12L10 17L20 7" stroke="#768F1C" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </span></td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'" (mouseenter)="setImageTag('mileage')"
                        (click)="setImageTag('mileage')" translate>Mileage of evs:</td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'" (mouseenter)="setImageTag('mileage')"
                        (click)="setImageTag('mileage')">
                        {{(configuratorService.configurator ? configuratorService.configurator.electricCarMileageLow :
                        1)
                        | number: '1.0-2':'de-DE'}} -
                        {{(configuratorService.configurator ? configuratorService.configurator.electricCarMileageHigh :
                        1)
                        | number: '1.0-2':'de-DE'}} km/Jahr</td>
                </tr>
                <tr>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"><span><svg width="24" height="24"
                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 12L10 17L20 7" stroke="#768F1C" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </span></td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('knowledgeLevel')" translate>Knowledge level:</td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('knowledgeLevel')">
                        {{getKnowledgeLevel(configuratorService.configurator ?
                        configuratorService.configurator.knowledgeLevel : 1) | translate}}</td>
                </tr>
                <tr [ngClass]="{'highlight': toBePrinted}">
                    <td [ngClass]=" toBePrinted ? 'low-padding' :'high-padding'"><span><svg width="24" height="24"
                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 12L10 17L20 7" stroke="#768F1C" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </span></td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('application1')" (click)="setImageTag('application1')" translate>
                        Application 1:</td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('application1')" (click)="setImageTag('application1')">
                        {{application1 ? translateService.instant(application1) : 'Nichts ausgewählt'}}</td>
                </tr>
                <tr>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"><span><svg width="24" height="24"
                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 12L10 17L20 7" stroke="#768F1C" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </span></td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('application2')" (click)="setImageTag('application2')" translate>
                        Application 2:</td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('application2')" (click)="setImageTag('application2')">
                        {{application2 ?translateService.instant(application2) : 'Nichts ausgewählt'}}</td>
                </tr>
                <tr>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"><span><svg width="24" height="24"
                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 12L10 17L20 7" stroke="#768F1C" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </span></td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('application3')" (click)="setImageTag('application3')" translate>
                        Application 3:</td>
                    <td [ngClass]="toBePrinted ? 'low-padding':'high-padding'"
                        (mouseenter)="setImageTag('application3')" (click)="setImageTag('application3')">
                        {{application3 ?translateService.instant(application3) :
                        'Nichts ausgewählt'}}</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="col-lg-5 col-md-12 right-div d-flex flex-column">
        <div *ngIf="imageTag == 'powerGeneration' && configuratorService.configurator.PVSystemPower == 0">
            <div class="d-flex gap-4" [ngClass]="{'extra-height': isTab}">
                <p [ngClass]="{'text-center': isMobile || isTab}"> {{'input.noPVText' |
                    translate}}</p>
            </div>
        </div>
        <ng-container *ngIf=" isDesktop && imageTag=='application1' && !toBePrinted">
            <ng-container [ngSwitch]="application1?.toLowerCase()">
                <div *ngSwitchCase="'peak load shaving'">
                    <div class="d-flex gap-4">
                        <img class="application-icon" src="assets/img/configurator/step-6-img-2.png" alt="">
                        <p>{{'input.peakload.text' | translate}}</p>
                    </div> <video class="video-content w-100 video h-100" id="video0" autoplay playsInline>
                        <source src="assets/success_page_media/Applications/peak_shaving_dt.mp4" type=" video/mp4">
                    </video>
                </div>
                <div *ngSwitchCase="'self consumption optimization'">
                    <div class="d-flex gap-4">
                        <img class="application-icon" src="assets/img/configurator/step-6-img-1.png" alt="">
                        <p>{{'input.selfconsumption.text' | translate}}</p>
                    </div> <video class="video-content w-100 video h-100" id="video0" autoplay playsInline>
                        <source src="assets/success_page_media/Applications/selv_cons_opt_dt.mp4" type=" video/mp4">
                    </video>
                </div>
                <div *ngSwitchCase="'backup power'">
                    <div class="d-flex gap-4">
                        <img class="application-icon" src="assets/img/configurator/step-6-img-3.png" alt="">
                        <p>{{'input.backuppower.text' | translate}}</p>
                    </div><video class="video-content w-100 video h-100" id="video0" autoplay playsInline>
                        <source src="assets/success_page_media/Applications/backup_power_dt.mp4" type=" video/mp4">
                    </video>
                </div>
                <ng-container *ngSwitchDefault><img [ngClass]="toBePrinted ? 'report' : 'right-content-banner'"
                        [src]="setInputImageAddress()" alt=""></ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isDesktop && imageTag == 'application2'">
            <ng-container [ngSwitch]="application2?.toLowerCase()">
                <div *ngSwitchCase="'peak load shaving'">
                    <div class="d-flex gap-4">
                        <img class="application-icon" src="assets/img/configurator/step-6-img-2.png" alt="">
                        <p>{{'input.peakload.text' | translate}}</p>
                    </div><video class="video-content w-100 video h-100" id="video0" autoplay playsInline>
                        <source src="assets/success_page_media/Applications/peak_shaving_dt.mp4" type=" video/mp4">
                    </video>
                </div>
                <div *ngSwitchCase="'self consumption optimization'">
                    <div class="d-flex gap-4">
                        <img class="application-icon" src="assets/img/configurator/step-6-img-1.png" alt="">
                        <p>{{'input.selfconsumption.text' | translate}}</p>
                    </div> <video class="video-content w-100 video h-100" id="video0" autoplay playsInline>
                        <source src="assets/success_page_media/Applications/selv_cons_opt_dt.mp4" type=" video/mp4">
                    </video>
                </div>
                <div *ngSwitchCase="'backup power'">
                    <div class="d-flex gap-4">
                        <img class="application-icon" src="assets/img/configurator/step-6-img-3.png" alt="">
                        <p>{{'input.backuppower.text' | translate}}</p>
                    </div><video class="video-content w-100 video h-100" id="video0" autoplay playsInline>
                        <source src="assets/success_page_media/Applications/backup_power_dt.mp4" type=" video/mp4">
                    </video>
                </div>
                <ng-container *ngSwitchDefault><img [ngClass]="toBePrinted ? 'report' : 'right-content-banner'"
                        [src]="setInputImageAddress()" alt=""></ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isDesktop && imageTag == 'application3' && !toBePrinted">
            <ng-container [ngSwitch]="application3?.toLowerCase()">
                <div *ngSwitchCase="'peak load shaving'">
                    <div class="d-flex gap-4">
                        <img class="application-icon" src="assets/img/configurator/step-6-img-2.png" alt="">
                        <p>{{'input.peakload.text' | translate}}</p>
                    </div> <video class="video-content w-100 video h-100" id="video0" autoplay playsInline>
                        <source src="assets/success_page_media/Applications/peak_shaving_dt.mp4" type=" video/mp4">
                    </video>
                </div>
                <div *ngSwitchCase="'self consumption optimization'">
                    <div class="d-flex gap-4">
                        <img class="application-icon" src="assets/img/configurator/step-6-img-1.png" alt="">
                        <p>{{'input.selfconsumption.text' | translate}}</p>
                    </div> <video class="video-content w-100 video h-100" id="video0" autoplay playsInline>
                        <source src="assets/success_page_media/Applications/selv_cons_opt_dt.mp4" type=" video/mp4">
                    </video>
                </div>
                <div *ngSwitchCase="'backup power'">
                    <div class="d-flex gap-4">
                        <img class="application-icon" src="assets/img/configurator/step-6-img-3.png" alt="">
                        <p>{{'input.backuppower.text' | translate}}.</p>

                    </div>
                    <video class="video-content w-100 video h-100" id="video0" autoplay playsInline>
                        <source src="assets/success_page_media/Applications/backup_power_dt.mp4" type=" video/mp4">
                    </video>
                </div>
                <ng-container *ngSwitchDefault><img [ngClass]="toBePrinted ? 'report' : 'right-content-banner'"
                        [src]="setInputImageAddress()" alt=""></ng-container>
            </ng-container>
        </ng-container>
        <ng-container
            *ngIf="!((isMobile ||isTab || toBePrinted)  && imageTag =='powerGeneration'&& configuratorService.configurator.PVSystemPower ==0) && !(isDesktop && (imageTag == 'application1' || imageTag == 'application2'|| imageTag == 'application3'))">
            <img [ngClass]="toBePrinted ? 'report' : 'right-content-banner'" [src]="setInputImageAddress()"
                alt=""></ng-container>

    </div>
</div>
import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const b2cPolicies = {
    names: {
        SignIn: "B2C_1_ECS_SignIn",
        ResetPassword: "B2C_1_ECS_Reset_Password",
        SignUp: "B2C_1_ECS_SignUp"
    },
    authorities: {
        SignIn: {
            authority: "https://tesvoltpartnertest.b2clogin.com/tesvoltpartnertest.onmicrosoft.com/B2C_1_ECS_SignIn",
        },
        SignUp: {
            authority: "https://tesvoltpartnertest.b2clogin.com/tesvoltpartnertest.onmicrosoft.com/B2C_1_ECS_SignUp"
        },
        resetPassword: {
            authority: "https://tesvoltpartnertest.b2clogin.com/tesvoltpartnertest.onmicrosoft.com/B2C_1_ECS_Reset_Password"
        }
    },
    authorityDomain: "tesvoltpartnertest.b2clogin.com"
};


export const msalConfig: Configuration = {
    auth: {
        clientId: 'ab84ce8e-2d63-4d26-b49e-5d9bf7e6e72f',
        authority: b2cPolicies.authorities.SignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: 'http://localhost:4200/',
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
    },
    system: {
        loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
                // console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

export const protectedResources = {
    api: {
        endpoint: "https://localhost:44365/User",
        scopes: ["https://tesvoltpartnertest.onmicrosoft.com/ecs-web/ecs.read",
            "https://tesvoltpartnertest.onmicrosoft.com/ecs-web/ecs.write"
        ],
    },
}
export const loginRequest = {
    scopes: ["email"]
};
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ConfiguratorService } from "src/app/configurator/utility/configurator.service";
import { AppConstants } from "src/app/core/constants";
import { UserService } from "src/app/services/user.service";
import { User } from "../utility/user.model";
import { VerificationData } from "../utility/verification-data.model";

@Injectable({
  providedIn: 'root'
}) export class VerificationService {
  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService, private userService: UserService, private configuratorService: ConfiguratorService) { }
  public marketingData: VerificationData = { emailId: '', levelOfUrgency: 7, source: [], portalUsage: [] };

  submitMarketingData() {
    this.marketingData.emailId = this.userService.loggedInUser.email;
    this.http.post(AppConstants.API_URL + 'user/collect-user-verification-data', this.marketingData).subscribe(() => {

    });
  }

  submitUserData(user: User) {
    user.localAccountID = "";
    user.prefix = Number(user.prefix);
    this.http.post(AppConstants.API_URL + 'User/register-new-user', user).subscribe((res: any) => {
      if (res.userId == '' && res.status == 'error') {
        this.toastr.error(res.message);
      } else {
        if (this.configuratorService.configurator.id) {
          this.http.patch(AppConstants.API_URL + "Configurator/saveconfiguratordata", { configurator: this.configuratorService.configurator.id, user: res.userId }).subscribe(() => { });
        }
        this.router.navigate(['auth', 'email-confirmation']);
      }
    }, () => {
      alert("Something went wrong..");
    });
  }

  submitMarketingForm(user: User, suggestedProductId: number, suggestedProductName: string) {

    user.interestScore =

      ((this.configuratorService.configurator.step1UserInteracted ? (1 + (Math.min(this.configuratorService.configurator.step1Duration / 1000, 5))) : 0)

        +
        (this.configuratorService.configurator.step2UserInteracted ? Math.min(this.configuratorService.configurator.step2Duration / 1000, 5) + 1 : 0)

        +
        (this.configuratorService.configurator.step3UserInteracted ? Math.min(this.configuratorService.configurator.step3Duration / 1000, 5) + 1 : 0)

        +
        (this.configuratorService.configurator.step4UserInteracted ? Math.min(this.configuratorService.configurator.step4Duration / 1000, 5) + 1 : 0)

        +
        (this.configuratorService.configurator.step5UserInteracted ? Math.min(this.configuratorService.configurator.step5Duration / 1000, 5) + 1 : 0)

        +
        (this.configuratorService.configurator.step6UserInteracted ? Math.min(this.configuratorService.configurator.step6Duration / 1000, 5) + 1 : 0)) *

      this.configuratorService.configurator.knowledgeLevel;

    user.interestScore = Math.round(user.interestScore);
    if (user.interestScore == 0) {
      user.interest = "Very low";
    } else if (user.interestScore > 0 && user.interestScore < 60) {
      user.interest = "Low";
    } else if (user.interestScore >= 60 && user.interestScore < 120) {
      user.interest = "Medium";
    } else {
      user.interest = "High";
    }
    this.http.post(AppConstants.API_URL + 'User/register-new-user-from-marketing-form', user).subscribe((res: any) => {
      if (res.userId == '' && res.status == 'error') {
        this.toastr.error(res.message);
      } else {
        if (this.configuratorService.configurator.id) {
          this.setUserInConfiguration(res.userId, suggestedProductId, suggestedProductName); this.userService.loggedInUser = user;
        }
        this.router.navigate(['configurator', 'acknowledgement']);
        // alert("TODO ... stopping navigation to acknowledgement component ... flow will end here");
      }
    }, () => {
      alert("Something went wrong..");
    });
  }

  setUserAsEmailVerified(requestPayload: any) {
    return this.http.post(AppConstants.API_URL + 'User/set-user-as-email-verified', requestPayload);
  }


  setUserInConfiguration(userId: any, suggestedProductId: any, suggestedProductName: any) {
    this.http.patch(
      AppConstants.API_URL + "Configurator/saveconfiguratordata",
      {
        configurator: this.configuratorService.configurator.id,
        user: userId,
        suggestedProductId: suggestedProductId,
        suggestedProductName: suggestedProductName
      }
    ).subscribe(() => { });
  }

  detectMob() {
    // const toMatch = [
    //   /Android/i,
    //   /webOS/i,
    //   /iPhone/i,
    //   /iPod/i,
    //   /BlackBerry/i,
    //   /Windows Phone/i
    // ];
    //
    // return toMatch.some((toMatchItem) => {
    //   return navigator.userAgent.match(toMatchItem);
    // });
    let isMobile = false
    const screenWidth = window.innerWidth;
    if (screenWidth <= 430) {
      isMobile = true
    }
    return isMobile;
  }

  detectTab() {
    let isTablet = false
    const screenWidth = window.innerWidth;
    if (screenWidth > 431 && screenWidth <= 1024) {
      isTablet = true
    }

    // const userAgent = navigator.userAgent.toLowerCase();
    // console.log(userAgent);
    // const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    return isTablet;
  }

}


import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { Router } from '@angular/router';
import { ConfiguratorService } from "../utility/configurator.service";

@Component({
  selector: 'end-customer-portal-configurator-header',
  templateUrl: './configurator-header.component.html',
  styleUrls: ['./configurator-header.component.scss']
})
export class ConfiguratorHeaderComponent {

  @Input() pageHeading!: string;
  @Input() pageHeading2!: string;
  @Input() currentStep: number = 0;
  @Input() routeToNextStep!: any;
  @Input() isActive!: boolean;
  constructor(private router: Router, public configuratorService: ConfiguratorService) {

  }

  getStepImage(step: number, device: string = '') {
    if (this.currentStep == 0) {
      if (device == 'mobile') {
        return `./assets/img/configurator/mobile/p${step}-active.png`;
      }
      if (device == 'tablet') {
        return `./assets/img/configurator/tablet/p${step}-active-0.png`;
      }
      // return `./assets/img/configurator/p${step}-active-0.png`;
      return `./assets/img/configurator/p${step}-inactive-1.png`;
    }
    if (this.currentStep >= step) {
      if (device == 'mobile') {
        if (this.currentStep == step) {
          return `./assets/img/configurator/mobile/p${step}-active-1.png`;
        }
        return `./assets/img/configurator/mobile/p${step}-active.png`;
      }
      if (this.currentStep == step) {
        return `./assets/img/configurator/p${step}-active-1.png`;
      }
      return `./assets/img/configurator/p${step}-active-0.png`;
    } else {
      if (device == 'mobile') {
        return `./assets/img/configurator/mobile/p${step}-inactive.png`;
      }
      if (device == 'tablet') {
        return `./assets/img/configurator/tablet/p${step}-active-0.png`;
      }

      return `./assets/img/configurator/p${step}-inactive-1.png`;
    }
  }



  getStepTextColor(step: number) {
    return this.currentStep >= step ? '#768F1C' : '#292929';
  }
  getNextStepUrl() {
    return ('/configurator/step' + (this.currentStep + 1)).toString();
  }

  goToPreviousStep() {
    this.configuratorService.addStepDuration(this.currentStep);

    if (this.configuratorService.isRunningInWindow) {
      this.configuratorService.stepToShowInWindow--;
    } else {
      if (this.currentStep > 1) {
        this.currentStep--;
        this.router.navigate([`configurator/step${this.currentStep}`]);

      }
    }
  }


}


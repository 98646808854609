import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { DataShareService } from "./services/data-share.service";
import { UserService } from "./services/user.service";

@Component({
  selector: 'end-customer-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'end-customer-portal-web';
  isLoggedIn = false;
  private readonly _destroying$ = new Subject<void>();
  closeResult = '';
  sidebarExpanded = false;
  showFooter = true;
  // portait = window.innerHeight > window.innerWidth;

  @ViewChild('headerscrolltarget')
  headerscrolltarget!: ElementRef;
  constructor(private userService: UserService, private ngxSpinner: NgxSpinnerService,
    public dataShareService: DataShareService) {
    this.dataShareService.scrollToNavCalled.subscribe(() => {
      this.headerscrolltarget.nativeElement.scrollIntoView();
    });
    this.dataShareService.navExpanded.subscribe(
      val => {
        this.sidebarExpanded = val;
      }
    )
    this.dataShareService.handleCallCountForLoader.subscribe(() => {
      if (this.dataShareService.openCalls == 0) {
        this.ngxSpinner.hide();
      } else {
        this.ngxSpinner.show();
      }
    })
    this.userService.fetchIsLoggedInStatus.subscribe(status => this.isLoggedIn = status);
    if (window.location.href.includes('ees')) {
      this.showFooter = false;
    }
  }
  // handleToggleSidebar() {
  //   this.sidebarExpanded = !this.sidebarExpanded;
  // }
  // @HostListener('window:orientationchange')
  // setPortait() {
  //   this.portait = window.innerHeight < window.innerWidth;
  // }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
